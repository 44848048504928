'use client'

import { useTranslation } from 'next-i18next';

export default function ToastMessage({ title, message, onClick }: { title: string, message: string, onClick?: () => void }) {
    const { t } = useTranslation();

    return (
        <div className="toast-message-container">
            <p className="title">{title}</p>
            <main>{message}</main>
            <footer>
                <button className="gold" onClick={onClick}>{t("COMMON.CONFIRM")}</button>
            </footer>
        </div>
    )
}