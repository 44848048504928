import { UserType } from "./user"

export interface NoticeType {
    site?: string,
    id?: number,
    title: string,
    is_popup: boolean,
    content: string,
    written_at: string,
    language?: string,
    is_hidden: boolean,
}

export enum QuestionKindEnum {
    NORMAL = 1,
    DEPOSIT = 2,
}
export const QUESTION_KIND_LABELS = {
    [QuestionKindEnum.NORMAL]: "일반",
    [QuestionKindEnum.DEPOSIT]: "계좌",
}

export enum QuestionStatusEnum {
    REQUESTED = 1,
    PENDING = 2,
    ANSWERED = 3,
}
export const QUESTION_STATUS_LABELS = {
    [QuestionStatusEnum.REQUESTED]: "요청",
    [QuestionStatusEnum.PENDING]: "대기",
    [QuestionStatusEnum.ANSWERED]: "완료",
}

export interface QuestionType {
    site?: string
    id: number
    kind: QuestionKindEnum,
    status: QuestionStatusEnum
    user: UserType
    title: string
    content: string
    written_at: string
    is_answered: boolean
    answer: string
    answered_at: string
    memo: string
}

export interface NewQuestionType {
    site?: string
    id: undefined,
    title: string
    content: string
}

export interface MessageType {
    site?: string
    id: number
    sender: UserType | undefined
    receiver: UserType
    title: string
    content: string
    is_read: boolean
    read_at: string
    sent_at: string
}

export const QUESTION_STATUS = {
    REQUESTED: 1,
    PENDING: 2,
    ANSWERED: 3,
} as const

export const QUESTION_KIND = {
    NORMARL: 1,
    DEPOSIT: 2,
} as const

export const QUESTION_STATUS_CHOICES = [
    [QUESTION_STATUS.REQUESTED, "요청"],
    [QUESTION_STATUS.PENDING, "대기"],
    [QUESTION_STATUS.ANSWERED, "완료"],
]

export function displayQuestionStatus(status: QuestionType['status']) {
    switch (status) {
        case QUESTION_STATUS.REQUESTED:
            return "요청"
        case QUESTION_STATUS.PENDING:
            return "대기"
        case QUESTION_STATUS.ANSWERED:
            return "완료"
    }
}

export function displayQuestionType(kind: QuestionType['kind']) {
    switch (kind) {
        case 1:
            return "일반 문의"
        case 2:
            return "계좌 문의"
        default:
            return "문의"
    }
}

export interface MacroType {
    site: string,
    id: number | undefined,
    label: string,
    content: string,
}
